















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ScrollUpButton extends Vue {
  private hide = true;

  scrollUp() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  changeState() {
    if (window.scrollY > 0) this.hide = false;
    else this.hide = true;
  }

  mounted() {
    document.addEventListener('scroll', this.changeState);
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.changeState);
  }
}
