

















import { Component, Vue } from 'vue-property-decorator';
import Navigation from '@/components/navigation/HeaderTabs.vue';
import ScrollUpButton from '@/components/ScrollUpButton.vue';

@Component({
  components: {
    Navigation,
    ScrollUpButton,
  },
})
export default class extends Vue {
  tabs = [
    { title: this.$t.title.users, icon: 'mdi-account-search', link: '/admin/users' },
    { title: this.$t.title.feedback, icon: 'mdi-comment', link: '/admin/feedback' },
    { title: this.$t.title.other, icon: 'mdi-all-inclusive-box', link: '/admin/other' },
  ];
  get isAdmin() {
    return this.$route.path.startsWith('/admin');
  }
}
