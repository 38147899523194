



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {
  @Prop() private readonly tabs!: {
    title?: string,
    link?: string,
    icon?: string,
  };
}
